export const ModalManager = (() => {
    const modals = {};
    const activeModals = {};
    let currentModal = null;

    const openModal = (modalId) => {
        const modal = document.getElementById(modalId);
        if (!modal) return;

        // Si la ventana modal no es de creación dinámica, se añade al listado de modales existentes
        if (!modals[modalId]) {
            modals[modalId] = modal;
        }

        // Quitar scroll del body
        if (!document.body.classList.contains('overflow-hidden')) {
            document.body.classList.add('overflow-hidden');
        }

        // Apertura de la modal
        modal.classList.remove('invisible', 'hidden');
        currentModal = modal;

        // Añadir modal al objeto activeModals
        activeModals[modalId] = modal;

        // Contenido
        const content = modal.querySelector('.rem-modal-content');

        if (content) {
            setTimeout(() => { content.classList.add('opacity-100', 'scale-100'); }, 10);
        }

        // Overlay
        const overlay = modal.querySelector('.rem-modal-overlay');

        if (overlay) {
            setTimeout(() => { overlay.classList.add('opacity-100'); }, 10);

            // Cerrar modal al hacer clic en el fondo, pero sólo si no está bloqueada
            if (!modal.dataset.locked) {
                overlay.addEventListener('click', handleOverlayClick);
            }
        }

        // Añadir eventos para cerrar modal
        modal.querySelectorAll('[data-modal-close]').forEach(btn => {
            btn.addEventListener('click', () => closeModal(modalId));
        });

        // Añadir evento para cerrar modal con la tecla "Escape"
        document.addEventListener('keydown', handleEscapeKey);

        return {
            modal,
            lock: () => lockModal(modalId),
        };
    };

    const closeModal = (modalId) => {
        const modal = document.getElementById(modalId);
        if (!modal) return;

        // Cierre de la modal
        setTimeout(() => { modal.classList.add('invisible', 'hidden'); }, 300);

        // Eliminar modal del objeto activeModals
        delete activeModals[modalId];

        // Contenido
        const content = modal.querySelector('.rem-modal-content');

        if (content) {
            content.classList.remove('opacity-100', 'scale-100');
        }

        // Overlay
        const overlay = modal.querySelector('.rem-modal-overlay');

        if (overlay) {
            overlay.classList.remove('opacity-100');

            // Remover listener del overlay cuando se cierra la modal
            overlay.removeEventListener('click', handleOverlayClick);
        }

        // Si se está cerrando la modal activa, actualizar el estado
        if (currentModal === modal) {
            currentModal = null;

            // Si hay otra modal abierta, reasignar el event listener
            if (Object.keys(activeModals).length > 0) {
                const lastModalId = Object.keys(activeModals)[Object.keys(activeModals).length - 1];
                currentModal = activeModals[lastModalId];
            } else {
                document.removeEventListener('keydown', handleEscapeKey);
            }
        }

        // Si se cierra la última modal hay que quitar el overflow-hidden del body
        if (Object.keys(activeModals).length === 0) {
            document.body.classList.remove('overflow-hidden');
        }
    };

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape' && currentModal && !currentModal.dataset.locked) {
            closeModal(currentModal.id);
        }
    };

    const handleOverlayClick = () => {
        if (currentModal && !currentModal.dataset.locked) {
            closeModal(currentModal.id);
        }
    };

    const lockModal = (modalId) => {
        const modal = document.getElementById(modalId);
        if (modal) {
            modal.dataset.locked = 'true';
            modal.querySelector('.rem-modal-close-btn')?.classList.add('hidden');

            const overlay = modal.querySelector('.rem-modal-overlay');
            if (overlay) {
                overlay.removeEventListener('click', handleOverlayClick);
            }
        }
        return {
            modal,
            open: () => openModal(modalId),
        };
    };

    const unlockModal = (modalId) => {
        const modal = document.getElementById(modalId);
        if (modal) {
            delete modal.dataset.locked;
            modal.querySelector('.rem-modal-close-btn')?.classList.remove('hidden');

            const overlay = modal.querySelector('.rem-modal-overlay');
            if (overlay) {
                overlay.addEventListener('click', handleOverlayClick);
            }
        }
    };

    const setModalContent = (modalId, content) => {
        const modal = document.getElementById(modalId);
        if (modal) {
            const body = modal.querySelector('[data-modal-body]');
            if (body) {
                body.innerHTML = content;
            }
        }
    };

    const attachOpenModalButton = (buttonId, modalId) => {
        const button = document.getElementById(buttonId);
        if (button) {
            button.addEventListener('click', () => openModal(modalId));
        }
    };

    const createModal = (modalId, content, additionalClasses = '') => {
        let modal;

        if (modals[modalId]) {
            modal = modals[modalId];
        } else {
            modal = document.createElement('div');
            modal.id = modalId;
            modal.className = 'rem-modal-container invisible hidden';
            modal.innerHTML = `
                <div class="rem-modal-wrapper">
                    <div class="rem-modal-overlay"></div>
                    <div class="rem-modal-positioner">
                        <div class="rem-modal-content ${additionalClasses}">
                            <div class="p-6" data-modal-body="${modalId}">
                                ${content}
                            </div>
                            <button type="button" data-modal-close class="rem-modal-close-btn">
                                <i class="icon-cancel"></i>
                                <span class="sr-only">Cerrar modal</span>
                            </button>
                        </div>
                    </div>
                </div>`;
            document.body.appendChild(modal);
            modals[modalId] = modal;
        }

        return {
            modal,
            open: () => openModal(modalId),
            lock: () => lockModal(modalId),
        };
    };

    const attachActionToButton = (buttonId, action) => {
        const button = document.getElementById(buttonId);
        if (button) {
            button.addEventListener('click', action);
        }
    };

    const setupButtonsWithModalData = () => {
        document.querySelectorAll('[data-rem-modal]').forEach(el => {
            if (!el.dataset.modalProcessed) {
                const modalId = el.dataset.remModal;
                const modalTitle = el.dataset.modalTitle || '';
                const modalContentText = el.dataset.modalContent || '';
                const modalClasses = el.dataset.modalClasses || '';

                let modalContent = '';

                if (modalTitle) {
                    modalContent += `<header class="text-lg font-semibold text-center p-2 border-b">${modalTitle}</header>`;
                }

                modalContent += `<div class="text-center${modalTitle ? ' p-6' : ''}">${modalContentText}</div>`;

                el.addEventListener('click', () => {
                    if (!modals[modalId]) {
                        createModal(modalId, modalContent, (modalTitle ? '[&>*]:p-0 ' : '') + modalClasses).open();
                    } else {
                        openModal(modalId);
                    }
                });

                el.dataset.modalProcessed = 'true';
            }
        });
    };

    return {
        openModal,
        closeModal,
        lockModal,
        unlockModal,
        setModalContent,
        attachOpenModalButton,
        createModal,
        attachActionToButton,
        setupButtonsWithModalData
    };
})();